// Dependencies
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
// ...

function Meta({ description, lang, meta, title, shareImage, css = `` }) {

  const { defaultTitle, defaultDescription, defaultShareImage, metaKeywords } = useSettings();

  const metaTitle = title || defaultTitle;
  const metaDescription = description || defaultDescription;
  const metaImage = `https:${shareImage}` || defaultShareImage ? `https:${defaultShareImage.fluid.src}` : '';
	const metaAuthor = ``;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
				},
				{
          name: 'keywords',
          content: metaKeywords
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          property: 'twitter:image',
          content: metaImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      style={[{
        "cssText": css
      }]}
    />
  );
};

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  shareImage: PropTypes.object
};

export default Meta;
