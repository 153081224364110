// Dependencies
import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
import Link from "../components/Link";
import { RepresentsLogo, LibraryLogo, SearchIcon, InfoIcon, DownArrowIcon, Burger, CloseIcon, PlusIcon } from "../components/SVGs";


const PageSwitcher = ({ collections, location }) => {

	// Content
	const activePage = collections.find(item => `/${item.slug}` === location.pathname);

	// UX
	const [showCollections, setCollections] = useState(false);

	// UI
	// const toggle = (bool) => {
	// 	setCollections(bool);
	// }

	return (
		<div className="header__collections nav-item" onMouseLeave={() => setCollections(false)}>
			<button className="color-rollover" onClick={() => setCollections(true)}>
				{activePage ? (
					<span>{activePage.title}</span>
				) : (
					<span>{collections[0].title}</span>
				)}
        <DownArrowIcon />
			</button>

			{showCollections && (
				<div className="collections">
					{collections.map(node => {
						return (
							<Link to={`/${node.slug}`} key={`collection-${node.slug}`}>{node.title}</Link>
						)
					})}
				</div>
			)}
		</div>
	)
}

const Header = ({ isLibrary, hideHeader, showIntro, setShowIntro, location }) => {

	// Content
	const { discoSearchUrl, collections } = useSettings();

	// UX
	const [showLinks, setShowLinks] = useState(false);
	const pathname = location ? location.pathname : '/';

	// UI
	const toggleLinks = () => {
		setShowLinks(prev => !prev);
	}

	// Style
	const className = `${showLinks ? 'show-links' : ''} ${showIntro ? 'has-intro' : ''} ${showIntro && hideHeader ? 'hide' : ''} ${isLibrary ? 'is-library' : 'is-represents'}`;

  return (
    <header id="header" className={className}>
      <div className="header__wrapper">
        <AnchorLink to={`${pathname}#top`} className="header__logo">
          {isLibrary ? <LibraryLogo /> : <RepresentsLogo />}
        </AnchorLink>

				<button className="header__btn gutters" onClick={toggleLinks}>
					{showLinks ? (
						<div className="nav-item">
							<span>Close</span>
							<CloseIcon />
						</div>
					) : (
						<div className="nav-item menu-icon">
							<span>Menu</span>
							<Burger />
						</div>
					)}
				</button>
        
				<div className="header__links">
					{/* Collections */}
					{!showIntro && location && collections.length > 1 && !isLibrary && (
						<PageSwitcher collections={collections} location={location} />
					)}

					{/* Search Link */}
          <a href={discoSearchUrl} className="color-rollover nav-item">
            <span>Search</span>
            <SearchIcon />
          </a>

					{/* Info link (scroll to bottom) */}
          <AnchorLink to={`${pathname}#info`} className="color-rollover nav-item" onAnchorLinkClick={() => setShowIntro(false)}>
            <span>Info</span>
            <InfoIcon />
          </AnchorLink>
        </div>
      </div>
    </header>
  )
}

export default Header
