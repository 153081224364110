// Dependencies
import React from "react"
// Components
import Header from "../components/Header"
import Meta from "../components/Meta"
import Footer from "../components/Footer"
// CSS
import "../css/styles.scss"

const Layout = ({ title, isLibrary, showIntro, setShowIntro, hideHeader, location, css, children }) => (
  <main className={isLibrary ? 'library' : 'represents'}>
    <Meta title={title} css={css} />
    <Header isLibrary={isLibrary} hideHeader={hideHeader} showIntro={showIntro} setShowIntro={setShowIntro} location={location} />
		<div id="top" />
    {children}
		<Footer />
  </main>
)

export default Layout
