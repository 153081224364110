// Dependencies
import React from "react";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import Markdown from "../components/Markdown";

const YEAR = new Date().getFullYear();

const Footer = () => {

	const { legalFooter } = useSettings();

	return (
		<footer className="guttersx2">
			<div>{`© ${YEAR} `}</div>
			<Markdown field={legalFooter} />
		</footer>
	)
}

export default Footer