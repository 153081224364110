import { graphql, useStaticQuery } from "gatsby";

export default function () {

  const data = useStaticQuery(graphql`
    {
      representsSettings: contentfulSettings(contentful_id: {eq: "5a5cbROYDIS1Fs7ME1oN2m"}) {
        ...ContentfulSettingsFragment
      }
			librarySettings: contentfulSettings(contentful_id: {eq: "152QfpeRZwZbgUxxzhhgH3"}) {
        ...ContentfulSettingsFragment
      }
    }
  `)

	if (process.env.GATSBY_ENV_LIBRARY) {
		return data.librarySettings
	} else {
		return data.representsSettings
	}
}

export const ContentfulSettingsFragment = graphql`
  fragment ContentfulSettingsFragment on ContentfulSettings {
    defaultTitle: metaTitle
		defaultDescription: metaDescription
		defaultShareImage: shareImage {
			fluid(maxHeight: 500) {
				...GatsbyContentfulFluid_noBase64
			}
		}
		metaKeywords
		contactEmail
		discoSearchUrl
		infoTitle
		infoAbout {
			childMarkdownRemark {
				html
			}
		}
		infoContactTitle
		infoBooseyCopyLink {
			childMarkdownRemark {
				html
			}
		}
		infoLibraryCopyLink {
			childMarkdownRemark {
				html
			}
		}
		collections {
			title: pageTitle
			slug
		}
		legalFooter {
			childMarkdownRemark {
				html
			}
		}
  }
`